<template>
    <k-layout>
        <header class="tw-bg-white tw-px-4 tw-pt-18 tw-pb-14 sm:tw-py-24">
            <div class="tw-text-center tw-mx-auto tw-w-full tw-max-w-screen-md">
                <h1 class="tw-text-5xl tw-text-gray-800 tw-leading-normal tw-mb-6 tw-font-bold">{{ $t("calculator.title") }}</h1>

                <h3 class="tw-mb-12 tw-text-gray-800 tw-text-xl tw-leading-relaxed tw-font-normal" v-if="appLocale !== defaultLocale">
                    {{ $t("calculator.subtitles.only_for_slovak") }}
                </h3>

                <h3 class="tw-mb-12 tw-text-gray-800 tw-text-xl tw-leading-relaxed tw-font-normal" v-html="$t('calculator.subtitles.possible_earnings')" />

                <div class="tw-flex tw-items-stretch tw-gap-4 tw-flex-col sm:tw-flex-row sm:tw-items-center">
                    <k-input
                        v-model="inputNumber"
                        :placeholder="$t('calculator.inputs.gross_monthly_income')"
                        @focus="$event.target.select()"
                        v-on:keyup.enter="computeSalaryObject(inputNumber)"
                        class="tw-flex-grow input-large"
                        inputmode="numeric"
                        large
                    />
                    <k-button
                        id="gtm_salary_calculator"
                        @click="computeSalaryObject(inputNumber)"
                        color="primary"
                        custom-class="tw-flex-shrink button-large tw-px-10 tw-text-base"
                    >
                        {{ $t("calculator.actions.calculate") }}
                    </k-button>
                </div>

                <p class="tw-mt-6 tw-text-xs tw-leading-relaxed tw-text-gray-800" v-html="$t('calculator.descriptions.main')" />
            </div>
        </header>

        <main id="resultContainer" class="tw-py-14 sm:tw-mt-8 md:tw-py-20 md:tw-px-10 lg:tw-py-24 lg:tw-px-4">
            <div class="tw-grid tw-grid-cols-1 tw-divide-y-2 tw-divide-gray-200 tw-mx-auto tw-w-full sm:tw-divide-none sm:tw-gap-10 lg:tw-max-w-screen-lg">
                <calculator-section
                    :title="$t('global.employment')"
                    :subtitle="$t('calculator.descriptions.current_income')"
                    :left-title="$t('calculator.net_monthly_income')"
                    :middle-title="$t('calculator.months_worked_in_year')"
                    :middle-subtitle="salaryObject ? 12 : 0"
                    :right-title="$t('calculator.super_gross_annual_salary')"
                >
                    <template #leftSubtitle> {{ employeeNettoSalary | monthly | calculatorNumber }} € </template>

                    <template #rightSubtitle> {{ employeeGrossSalary | calculatorNumber }} € </template>

                    <div v-if="salaryObject" class="tw-mt-4 tw-hidden lg:tw-block">
                        <v-skeleton-loader v-if="!showCharts" max-width="100%" type="image"></v-skeleton-loader>
                        <ka-employee-chart v-else :salaryObject="salaryObject"></ka-employee-chart>
                    </div>

                    <v-expand-transition>
                        <div v-show="showMore.employee" class="tw-mt-10 ">
                            <h4 class="tw-heading-4">{{ $t("calculator.employer_levies") }}</h4>

                            <calculator-conscription-table
                                :conscription="salaryObject ? salaryObject.employeeObject.employerConscription : example.employeeObject.employerConscription"
                                :app-locale="appLocale"
                            />

                            <h4 class="tw-mt-10">{{ $t("calculator.employee_contributions") }}</h4>

                            <calculator-conscription-table
                                :conscription="salaryObject ? salaryObject.employeeObject.employeeConscription : example.employeeObject.employeeConscription"
                                :tax="salaryObject ? salaryObject.employeeObject.tax : example.employeeObject.tax"
                                :app-locale="appLocale"
                                is-employee
                            />
                        </div>
                    </v-expand-transition>

                    <toggle-info v-model="showMore.employee" class="tw-mt-8" />
                </calculator-section>

                <calculator-section
                    :subtitle="$t('calculator.invoice_md')"
                    :left-title="$t('calculator.price_md')"
                    :middle-title="$t('calculator.months_worked_in_year')"
                    :middle-subtitle="salaryObject ? '10,5' : 0"
                    :right-title="$t('calculator.invoice_for_year')"
                    left-class="invoice-left-section"
                >
                    <template #title>
                        {{ $t("calculator.subtitles.price_md") }}
                        <sup class="tw-hidden tw-text-base tw--top-5 md:tw-inline">{{ $t("calculator.subtitles.price_md_sup") }}</sup>
                        <p class="tw-text-base md:tw-hidden">{{ $t("calculator.subtitles.price_md_sup") }}</p>
                    </template>

                    <template #leftSubtitle> {{ mandayNettoIncome | calculatorNumber }} € </template>

                    <template #leftAddition>
                        <v-fade-transition>
                            <div v-show="salaryObject" class="tw-text-xs tw-leading-relaxed">
                                {{ $t("calculator.descriptions.net_monthly_income") }}
                            </div>
                        </v-fade-transition>
                    </template>

                    <template #rightSubtitle> {{ mandayGrossIncome | calculatorNumber }} € </template>
                </calculator-section>

                <template v-if="recommendedJobs.length">
                    <div class="tw-bg-koderia tw-py-7 tw-px-5 md:tw-p-14 md:tw-rounded-2xl">
                        <div class="tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-mb-7 md:tw-mb-12">
                            <h2 class="tw-text-white tw-text-xl md:tw-text-4xl tw-font-bold">
                                {{ $t("calculator.subtitles.recommended_job_offers") }}
                            </h2>

                            <k-button color="secondary" class="recommended-job-btn" :to="{ name: 'project-index', params: { locale: routeLocale } }">
                                {{ $t("calculator.actions.find_job") }} >
                            </k-button>
                        </div>

                        <div class="tw-flex tw-flex-col tw-gap-12">
                            <calculator-job-offer
                                v-for="job in recommendedJobs"
                                :key="job.id"
                                :title="job.name"
                                :rateFrom="job.rateContractFrom"
                                :rateTo="job.rateContractTo"
                                :id="job.id"
                            />
                        </div>
                    </div>
                </template>

                <template v-if="!isLogged">
                    <div class="tw-relative tw-px-3 tw--mt-64 md:tw-p-0 md:tw-mt-12 lg:tw-mt-0">
                        <div
                            class="tw-absolute tw-h-28 tw-top-0 tw--mt-60 tw-left-0 tw-w-full tw-z-10 tw-bg-gradient-to-t tw-from-white tw-via-white tw-to-transparent"
                        />

                        <div
                            class="tw-text-center tw-bg-green-alt tw-rounded-2xl tw-border-koderia tw-border-2 tw--mt-32 tw-relative tw-py-10 tw-px-5 sm:tw-text-left md:tw--ml-5 md:tw--mr-5 lg:tw-p-14 lg:tw--ml-10 lg:tw--mr-10"
                        >
                            <h2 class="tw-text-4xl tw-text-gray-900 tw-font-bold tw-leading-tight tw-mb-4" v-html="$t('calculator.subtitles.find_out_more')" />
                            <p class="tw-text-gray-900 tw-leading-relaxed tw-text-lg" v-html="$t('calculator.descriptions.more_after_login')" />

                            <div class="tw-flex tw-flex-col tw-gap-6 tw-items-stretch tw-mt-8 sm:tw-items-center sm:tw-flex-row">
                                <k-button color="primary" custom-class="tw-py-3 tw-px-10 tw-text-base" @click="doAuth('auth-signin')">
                                    {{ $t("auth.actions.sign_in_simple") }}
                                </k-button>

                                <p class="tw-text-sm tw-text-gray-900 tw-leading-relaxed">
                                    <a @click="doAuth('auth-signup')" class="tw-font-bold tw-text-sm tw-text-gray-900 tw-leading-relaxed tw-underline">
                                        {{ $t("auth.actions.sign_up_here") }}
                                    </a>
                                </p>
                            </div>

                            <div class="tw-text-left tw-mt-12 tw-space-y-4">
                                <div class="tw-flex tw-items-center tw-gap-4">
                                    <k-icon name="done" class="tw-text-koderia" />
                                    <p class="tw-leading-relaxed">{{ $t("calculator.descriptions.your_md_rate") }}</p>
                                </div>

                                <div class="tw-flex tw-items-center tw-gap-4">
                                    <k-icon name="done" class="tw-text-koderia" />
                                    <p class="tw-leading-relaxed">{{ $t("calculator.descriptions.earn_for_trade") }}</p>
                                </div>

                                <div class="tw-flex tw-items-center tw-gap-4">
                                    <k-icon name="done" class="tw-text-koderia" />
                                    <p class="tw-leading-relaxed">{{ $t("calculator.descriptions.earn_on_sro") }}</p>
                                </div>

                                <div class="tw-flex tw-items-center tw-gap-4">
                                    <k-icon name="done" class="tw-text-koderia" />
                                    <p class="tw-leading-relaxed">{{ $t("calculator.descriptions.fees_and_taxes") }}</p>
                                </div>

                                <div class="tw-flex tw-items-center tw-gap-4">
                                    <k-icon name="done" class="tw-text-koderia" />
                                    <p class="tw-leading-relaxed">{{ $t("calculator.descriptions.option_for_you") }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-if="isLogged">
                    <h2 class="tw-heading-2 tw-hidden md:tw-block">{{ $t("calculator.subtitles.billing_options") }}</h2>

                    <calculator-section
                        :title="$t('calculator.trade_first_year')"
                        :left-title="$t('calculator.average_net_monthly_income')"
                        :middle-title="$t('calculator.months_worked_in_year')"
                        :middle-subtitle="salaryObject ? '10,5' : 0"
                        :right-title="$t('calculator.invoice_for_year')"
                    >
                        <template #leftSubtitle> {{ salaryObject ? salaryObject.contractorObject.nettoIncome : 0 | monthly | calculatorNumber }} € </template>

                        <template #rightSubtitle> {{ salaryObject ? salaryObject.contractorObject.grossIncome : 0 | calculatorNumber }} € </template>

                        <div v-if="salaryObject" class="tw-mt-4 tw-hidden lg:tw-block">
                            <v-skeleton-loader v-if="!showCharts" max-width="100%" type="image"></v-skeleton-loader>
                            <ka-contractor-chart v-else :salaryObject="salaryObject"></ka-contractor-chart>
                        </div>

                        <v-expand-transition>
                            <div v-show="showMore.contractor" class="tw-mt-10 ">
                                <h4 class="tw-heading-4">{{ $t("calculator.taxes_and_levies") }}</h4>
                                <div class="table-container">
                                    <div class="table-wrapper">
                                        <table>
                                            <thead class="title-text">
                                                <tr>
                                                    <th></th>
                                                    <th>{{ $tc("global.time.monthly", 1) }}</th>
                                                    <th>{{ $t("global.time.annual") }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{{ $t("calculator.health_insurance_contributions") }}</td>
                                                    <td>
                                                        {{
                                                            salaryObject ? salaryObject.contractorObject.medicalConscription : 0 | monthly | calculatorNumber
                                                        }}
                                                        €
                                                    </td>
                                                    <td>{{ salaryObject ? salaryObject.contractorObject.medicalConscription : 0 | calculatorNumber }} €</td>
                                                </tr>
                                                <tr>
                                                    <td>{{ $t("calculator.social_insurance_contributions") }}</td>
                                                    <td>0,00 €</td>
                                                    <td>0,00 €</td>
                                                </tr>
                                                <tr>
                                                    <td>{{ $t("calculator.income_tax") }}</td>
                                                    <td>{{ salaryObject ? salaryObject.contractorObject.tax : 0 | monthly | calculatorNumber }} €</td>
                                                    <td>{{ salaryObject ? salaryObject.contractorObject.tax : 0 | calculatorNumber }} €</td>
                                                </tr>
                                                <tr>
                                                    <td>{{ $t("calculator.together") }}</td>

                                                    <td>
                                                        {{
                                                            salaryObject
                                                                ? salaryObject.contractorObject.grossIncome - salaryObject.contractorObject.nettoIncome
                                                                : 0 | monthly | calculatorNumber
                                                        }}€
                                                    </td>
                                                    <td>
                                                        {{
                                                            salaryObject
                                                                ? salaryObject.contractorObject.grossIncome - salaryObject.contractorObject.nettoIncome
                                                                : 0 | calculatorNumber
                                                        }}€
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <p>
                                    {{ $t("calculator.billing_options_learn_more") }}
                                    <a
                                        href="https://koderia.sk/blog/ako-funguje-fakturacna-kalkulacka"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        class="tw-text-koderia tw-mt-4 tw-inline-block tw-underline"
                                    >
                                        {{ $t("calculator.billing_options_learn_more_link") }}
                                    </a>
                                </p>
                            </div>
                        </v-expand-transition>

                        <toggle-info v-model="showMore.contractor" class="tw-mt-4" />
                    </calculator-section>

                    <calculator-section
                        :title="$t('calculator.trade_2nd_and_next_year')"
                        :left-title="$t('calculator.average_net_monthly_income')"
                        :middle-title="$t('calculator.months_worked_in_year')"
                        :middle-subtitle="salaryObject ? '10,5' : 0"
                        :right-title="$t('calculator.invoice_for_year')"
                    >
                        <template #leftSubtitle>
                            {{ salaryObject ? salaryObject.longTermContractorObject.nettoIncome : 0 | monthly | calculatorNumber }} €
                        </template>

                        <template #rightSubtitle> {{ salaryObject ? salaryObject.longTermContractorObject.grossIncome : 0 | calculatorNumber }} € </template>

                        <div v-if="salaryObject" class="tw-mt-4 tw-hidden lg:tw-block">
                            <v-skeleton-loader v-if="!showCharts" max-width="100%" type="image"></v-skeleton-loader>
                            <ka-contractor-longterm-chart v-else :salaryObject="salaryObject"></ka-contractor-longterm-chart>
                        </div>

                        <v-expand-transition>
                            <div v-show="showMore.longtermContractor" class="tw-mt-10 ">
                                <h4 class="tw-heading-4">{{ $t("calculator.taxes_and_levies") }}</h4>
                                <div class="table-container">
                                    <div class="table-wrapper">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>{{ $tc("global.time.monthly", 1) }}</th>
                                                    <th>{{ $t("global.time.annual") }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{{ $t("calculator.health_insurance_contributions") }}</td>
                                                    <td>
                                                        {{
                                                            salaryObject
                                                                ? salaryObject.longTermContractorObject.medicalConscription
                                                                : 0 | monthly | calculatorNumber
                                                        }}€
                                                    </td>
                                                    <td>
                                                        {{ salaryObject ? salaryObject.longTermContractorObject.medicalConscription : 0 | calculatorNumber }} €
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{{ $t("calculator.social_insurance_contributions") }}</td>
                                                    <td>
                                                        {{
                                                            salaryObject
                                                                ? salaryObject.longTermContractorObject.socialConscription
                                                                : 0 | monthly | calculatorNumber
                                                        }}€
                                                    </td>
                                                    <td>
                                                        {{ salaryObject ? salaryObject.longTermContractorObject.socialConscription : 0 | calculatorNumber }} €
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{{ $t("calculator.income_tax") }}</td>
                                                    <td>{{ salaryObject ? salaryObject.longTermContractorObject.tax : 0 | monthly | calculatorNumber }} €</td>
                                                    <td>{{ salaryObject ? salaryObject.longTermContractorObject.tax : 0 | calculatorNumber }} €</td>
                                                </tr>
                                                <tr>
                                                    <td>{{ $t("calculator.together") }}</td>
                                                    <td>
                                                        {{
                                                            salaryObject
                                                                ? salaryObject.longTermContractorObject.grossIncome -
                                                                  salaryObject.longTermContractorObject.nettoIncome
                                                                : 0 | monthly | calculatorNumber
                                                        }}€
                                                    </td>
                                                    <td>
                                                        {{
                                                            salaryObject
                                                                ? salaryObject.longTermContractorObject.grossIncome -
                                                                  salaryObject.longTermContractorObject.nettoIncome
                                                                : 0 | calculatorNumber
                                                        }}€
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <p>
                                    {{ $t("calculator.billing_options_learn_more") }}
                                    <a
                                        href="https://koderia.sk/blog/ako-funguje-fakturacna-kalkulacka"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        class="tw-text-koderia tw-mt-4 tw-inline-block tw-underline"
                                    >
                                        {{ $t("calculator.billing_options_learn_more_link") }}
                                    </a>
                                </p>
                            </div>
                        </v-expand-transition>

                        <toggle-info v-model="showMore.longtermContractor" class="tw-mt-4" />
                    </calculator-section>

                    <calculator-section
                        :title="$t('calculator.company_sro')"
                        :left-title="$t('calculator.average_net_monthly_income')"
                        :middle-title="$t('calculator.months_worked_in_year')"
                        :middle-subtitle="salaryObject ? '10,5' : 0"
                        :right-title="$t('calculator.invoice_for_year')"
                    >
                        <template #leftSubtitle> {{ salaryObject ? salaryObject.companyObject.nettoIncome : 0 | monthly | calculatorNumber }} € </template>

                        <template #rightSubtitle> {{ salaryObject ? salaryObject.companyObject.grossIncome : 0 | calculatorNumber }} € </template>

                        <div v-if="salaryObject" class="tw-mt-4 tw-hidden lg:tw-block">
                            <v-skeleton-loader v-if="!showCharts" max-width="100%" type="image"></v-skeleton-loader>
                            <ka-company-chart v-else :salaryObject="salaryObject"></ka-company-chart>
                        </div>

                        <v-expand-transition>
                            <div v-show="showMore.sro" class="tw-mt-10 ">
                                <h4 class="tw-heading-4">{{ $t("calculator.company_tax") }}</h4>
                                <div class="table-container">
                                    <div class="table-wrapper">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>{{ $tc("global.time.monthly", 1) }}</th>
                                                    <th>{{ $t("global.time.annual") }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{{ $t("calculator.dividend_tax") }}</td>
                                                    <td>{{ salaryObject ? salaryObject.companyObject.gainTax : 0 | monthly | calculatorNumber }} €</td>
                                                    <td>{{ salaryObject ? salaryObject.companyObject.gainTax : 0 | calculatorNumber }} €</td>
                                                </tr>
                                                <tr>
                                                    <td>{{ $t("calculator.income_tax") }}</td>
                                                    <td>{{ salaryObject ? salaryObject.companyObject.tax : 0 | monthly | calculatorNumber }} €</td>
                                                    <td>{{ salaryObject ? salaryObject.companyObject.tax : 0 | calculatorNumber }} €</td>
                                                </tr>
                                                <tr>
                                                    <td>{{ $t("calculator.total_taxes") }}</td>
                                                    <td>
                                                        {{
                                                            salaryObject
                                                                ? salaryObject.companyObject.grossIncome - salaryObject.companyObject.nettoIncome
                                                                : 0 | monthly | calculatorNumber
                                                        }}€
                                                    </td>
                                                    <td>
                                                        {{
                                                            salaryObject
                                                                ? salaryObject.companyObject.grossIncome - salaryObject.companyObject.nettoIncome
                                                                : 0 | calculatorNumber
                                                        }}€
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <p>
                                    {{ $t("calculator.billing_options_learn_more") }}
                                    <a
                                        href="https://koderia.sk/blog/ako-funguje-fakturacna-kalkulacka"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        class="tw-text-koderia tw-mt-4 tw-inline-block tw-underline"
                                    >
                                        {{ $t("calculator.billing_options_learn_more_link") }}
                                    </a>
                                </p>
                            </div>
                        </v-expand-transition>

                        <toggle-info v-model="showMore.sro" class="tw-mt-4" />
                    </calculator-section>
                </template>
            </div>
        </main>
    </k-layout>
</template>

<script>
import Cookies from "js-cookie";
import { TweenLite } from "gsap/all";
import wageCalculator from "../addons/wage_calculator";

import ToggleInfo from "./components/toggle-info.vue";
import KaCompanyChart from "./ka-company-chart";
import KaEmployeeChart from "./ka-employee-chart";
import KaContractorChart from "./ka-contractor-chart";
import KaContractorLongtermChart from "./ka-contractor-longterm-chart";
import formatNumber from "./formatNumber";
import loadGoogleCharts from "./../components/ka-google-charts/google-charts-loader";
import CalculatorSection from "@web/calculator/components/calculator-section";
import CalculatorConscriptionTable from "@web/calculator/components/calculator-conscription-table";
import { DEFAULT_LOCALE, getRouteLocale } from "@web/constants/language";
import { getProjectsByProfessionAndWage } from "@web/utils/projects";
import { mapGetters } from "vuex";
import CalculatorJobOffer from "@web/calculator/components/calculator-job-offer.vue";
import KButton from "@web/components/core/k-button.vue";

export default {
    name: "CalculatorPage",
    components: {
        KButton,
        CalculatorJobOffer,
        CalculatorConscriptionTable,
        CalculatorSection,
        ToggleInfo,
        KaCompanyChart,
        KaEmployeeChart,
        KaContractorChart,
        KaContractorLongtermChart
    },
    mounted() {
        loadGoogleCharts();

        const { plat: salary } = this.$route.query;
        if (salary > 0 && !isNaN(salary)) {
            this.inputNumber = salary;

            setTimeout(() => {
                this.computeSalaryObject(salary);
            }, 1000);
        }
    },
    data() {
        return {
            defaultLocale: DEFAULT_LOCALE,
            showCharts: false,
            inputNumber: null,
            hrubaMzda: null,
            tweenedNumber: 0,
            showMore: {
                employee: false,
                contractor: false,
                longtermContractor: false,
                sro: false
            },
            zivotneMinimum: 210.2,
            salaryObject: null,
            constants: null,
            example: {
                employeeObject: {
                    type: "employee",
                    bruttoSalary: 0,
                    grossSalary: 0,
                    grossTaxBase: 0,
                    untaxablePartOfTaxBase: 0,
                    editedTaxBase: 0,
                    tax: 0,
                    nettoSalary: 0,
                    employeeConscription: [
                        {
                            description: "Medical insurance",
                            descriptionInSlovak: "Zdravotné poistenie",
                            value: 0,
                            percentage: 0.04
                        },
                        {
                            description: "Sick-pay insurance",
                            descriptionInSlovak: "Nemocenské poistenie",
                            value: 0,
                            percentage: 0.014
                        },
                        {
                            description: "Retirement insurance",
                            descriptionInSlovak: "Starobné poistenie",
                            value: 0,
                            percentage: 0.04
                        },
                        {
                            description: "Disability insurance",
                            descriptionInSlovak: "Invalidné poistenie",
                            value: 0,
                            percentage: 0.03
                        },
                        {
                            description: "Unemployment insurance",
                            descriptionInSlovak: "Poistenie v nezamestnanosti",
                            value: 0,
                            percentage: 0.01
                        }
                    ],
                    employerConscription: [
                        {
                            description: "Medical insurance",
                            descriptionInSlovak: "Zdravotné poistenie",
                            value: 0,
                            percentage: 0.1
                        },
                        {
                            description: "Sick-pay insurance",
                            descriptionInSlovak: "Nemocenské poistenie",
                            value: 0,
                            percentage: 0.014
                        },
                        {
                            description: "Retirement insurance",
                            descriptionInSlovak: "Starobné poistenie",
                            value: 0,
                            percentage: 0.14
                        },
                        {
                            description: "Disability insurance",
                            descriptionInSlovak: "Invalidné poistenie",
                            value: 0,
                            percentage: 0.03
                        },
                        {
                            description: "Unemployment insurance",
                            descriptionInSlovak: "Poistenie v nezamestnanosti",
                            value: 0,
                            percentage: 0.01
                        },
                        {
                            description: "Garantee surplus",
                            descriptionInSlovak: "Garančný fond",
                            value: 0,
                            percentage: 0.0025
                        },
                        {
                            description: "Reserves surplus",
                            descriptionInSlovak: "Rezervný fond",
                            value: 0,
                            percentage: 0.0475
                        },
                        {
                            description: "Accident insurance",
                            descriptionInSlovak: "Úrazové poistenie",
                            value: 0,
                            percentage: 0.008
                        }
                    ]
                },
                contractorObject: {
                    type: "contractor",
                    bruttoSalary: 0,
                    grossIncome: 0,
                    grossTaxBase: 0,
                    untaxablePartOfTaxBase: 0,
                    medicalConscription: 0,
                    tax: 0,
                    nettoIncome: 0
                },
                longContractor: null
            }
            // pageObject: {
            //     headerImage: '/web/header/calculator-header.jpg',
            //     headerObject: {
            //         heading: 'Mzdová kalkulačka',
            //         lead: 'Nástroj na výpočet fakturovanej sumy pri prechode na živnosť alebo S.R.O. z TPP pri zachovaní rovnakých ročných nákladov zamestnávateľa',
            //         containerClass: 'standard',
            //         color: 'white'
            //     },
            // },
        };
    },
    filters: {
        calculatorNumber: formatNumber,
        monthly: function(floatNumber) {
            return floatNumber / 12;
        },
        yearly: function(floatNumber) {
            return floatNumber * 12;
        },
        percentage: function(floatNumber) {
            return floatNumber * 100;
        }
    },
    computed: {
        ...mapGetters("NEW_PROJECTS", ["allProjects"]),
        isLogged() {
            return !!this.$store.state.AUTH.user;
        },
        profile() {
            return this.$store.state.USER.profile;
        },
        employeeNettoSalary() {
            return this.salaryObject ? this.salaryObject.employeeObject.nettoSalary : 0;
        },
        employeeGrossSalary() {
            return this.salaryObject ? this.salaryObject.employeeObject.grossSalary : 0;
        },
        mandayNettoIncome() {
            return this.salaryObject ? this.salaryObject.contractorObject.grossIncome / this.constants.contractorNumberOfWorkingDays : 0;
        },
        mandayGrossIncome() {
            return this.salaryObject ? this.salaryObject.contractorObject.grossIncome : 0;
        },
        appLocale() {
            return this.$store.getters.appLocale;
        },
        routeLocale() {
            return getRouteLocale();
        },
        recommendedJobs() {
            if (!this.profile || this.mandayNettoIncome === 0) {
                return [];
            }

            const formResults = {
                profession: this.profile.profession,
                data: {
                    wage: this.mandayNettoIncome,
                    technologiesKnown: this.profile.technologies || []
                }
            };

            return getProjectsByProfessionAndWage(this.allProjects, formResults);
        }
    },
    methods: {
        // type = auth-signin, auth-signup
        doAuth(type) {
            const redirectAfterLoginTo = {
                name: "Calculator",
                query: {
                    plat: this.inputNumber
                }
            };

            Cookies.set("redirect.after_login", JSON.stringify(redirectAfterLoginTo));

            this.$router.push({ name: type });
        },
        numberWithSpaces(x) {
            let retval = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            return retval.toString().replace(".", ",");
        },
        computeSalaryObject() {
            if (this.inputNumber && this.inputNumber >= 700 && this.inputNumber <= 15000) {
                this.hrubaMzda = this.inputNumber;
                this.constants = wageCalculator.constants;

                this.$vuetify.goTo("#resultContainer", { offset: 110 });

                this.showCharts = false;

                setTimeout(() => {
                    this.salaryObject = { ...this.salaryObject };
                    this.showCharts = true;
                }, 1250);
            }
        }
    },
    watch: {
        hrubaMzda: function(newValue) {
            TweenLite.to(this.$data, 1, {
                tweenedNumber: newValue
            });
        },
        tweenedNumber: function() {
            let bruttoSalary = this.tweenedNumber;
            let salaryObject = {
                employeeObject: {
                    type: "employee",
                    bruttoSalary: bruttoSalary
                },
                contractorObject: {
                    type: "contractor",
                    bruttoSalary: bruttoSalary
                },
                longTermContractorObject: {
                    type: "long-term-contractor",
                    bruttoSalary: bruttoSalary
                },
                companyObject: {
                    type: "company",
                    bruttoSalary: bruttoSalary
                }
            };
            Object.values(wageCalculator.employeeCounter).forEach(rule => {
                if (rule.condition(salaryObject.employeeObject)) {
                    rule.modifier(salaryObject.employeeObject);
                }
            });
            Object.values(wageCalculator.contractorCounter).forEach(rule => {
                if (rule.condition(salaryObject.contractorObject)) {
                    rule.modifier(salaryObject.contractorObject);
                }
            });
            Object.values(wageCalculator.longTermContractorCounter).forEach(rule => {
                if (rule.condition(salaryObject.longTermContractorObject)) {
                    rule.modifier(salaryObject.longTermContractorObject);
                }
            });
            Object.values(wageCalculator.companyCounter).forEach(rule => {
                if (rule.condition(salaryObject.companyObject)) {
                    rule.modifier(salaryObject.companyObject);
                }
            });
            this.salaryObject = salaryObject;
        }
    }
};
</script>
<style lang="scss">
@import "@web/sass/variables.scss";
.table-container {
    .table-wrapper {
        width: 100%;

        @include -md {
            overflow-x: scroll;
        }
    }

    table {
        width: 100%;
        min-width: 32.5rem;
        border-collapse: collapse;

        thead {
            tr {
                border-bottom: 0.063rem solid $koderia-whitesmoke;

                th {
                    @include padding-bottom-multiplier(1);

                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        text-align: right;
                    }
                }
            }
        }

        tbody {
            tr {
                border-bottom: 0.063rem solid $koderia-whitesmoke;

                &:last-child {
                    border-bottom: none;

                    td {
                        @include padding-top-multiplier(1);
                        font-size: map-get(map-get($text, title), size);
                        line-height: map-get(map-get($text, title), line-height);
                        font-weight: map-get(map-get($text, title), font-weight);
                    }
                }

                td {
                    @include padding-top-multiplier(1);
                    @include padding-bottom-multiplier(1);

                    font-size: map-get(map-get($text, small), size);
                    line-height: map-get(map-get($text, small), line-height);

                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        text-align: right;
                    }
                }
            }
        }
    }
}

.input-large {
    .k-input.v-input {
        .v-input__slot {
            padding: 1rem !important;
            box-shadow: 0px 3px 0px 0px $koderia-gray-300;

            input {
                font-size: 1.25rem;
                line-height: 1.6;
            }
        }

        &.v-input--is-focused {
            .v-input__slot {
                box-shadow: 0px 3px 0px 0px $koderia-gray-900 !important;
            }
        }

        &:not(.v-input--is-focused) {
            .v-input__slot:hover {
                background: #fff !important;
                border: 1px solid $koderia-gray-100 !important;
                box-shadow: 0px 3px 0px 0px $koderia-gray-300 !important;
            }
        }
    }
}

.button-large {
    padding-block: 18px;
}

.invoice-left-section {
    border-color: $koderia !important;
}

.recommended-job-btn {
    width: 290px;

    @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 16px;
    }
}
</style>
